import { ResponsiveText } from '@eventbrite/wagtail-components';
import uniqueId from 'lodash/uniqueId';
import React from 'react';
import { useTheme } from '../../../utils/context/theme-context';
import CTABlock, {
    CTABlockInterface,
} from '../../sharedComponents/cta-block/CTABlock';
import { CTA_BLOCK_LINK_VARIANT } from '../../sharedComponents/cta-block/utils';
import { BaseModuleProps } from '../types';
import { convertContentfulVariantToEnum, sanitizeHTML } from '../utils';
import './resourceCards.scss';

/**
 * FIGMA: https://www.figma.com/file/3sJczxP3TvSlyUIsSmNV1Z/New-Wagtail-Components%2FProduct-%2B-Brand?node-id=200%3A3108
 */

export interface IResourceCardsModule {
    title?: string;
    moduleDescription?: string;
    resourceCards: IResourceCard[];
    isContentFromContentful?: boolean;
    useH1?: boolean;
}

interface IResourceCard {
    headline: string;
    description?: string;
    ctaBlock?: CTABlockInterface;
    style?: React.CSSProperties;
}

const ResourceCardComponent: React.FC<IResourceCard & BaseModuleProps> = ({
    headline,
    description,
    ctaBlock,
    style,
    variant,
}) => {
    const baseOptions = { variant };
    const theme = useTheme();
    const [cardClass] = theme.generateStyles('card', baseOptions);
    const [textClass] = theme.generateStyles('card-text', baseOptions);
    return (
        <section
            className={`resource-card__container ${cardClass}`}
            style={style}
        >
            <header>
                <ResponsiveText
                    base={{ fontSize: 30, lineHeight: 40 }}
                    component="h4"
                    className={`resource-card__container__headline  ${textClass}`}
                >
                    {headline}
                </ResponsiveText>
            </header>
            {description && (
                <ResponsiveText
                    base={{ fontSize: 18, lineHeight: 24 }}
                    component="main"
                    className={`resource-card__container__description ${textClass}`}
                >
                    {description}
                </ResponsiveText>
            )}
            {ctaBlock && (
                <footer className="resource-card__container__cta">
                    <CTABlock
                        {...ctaBlock}
                        variant={CTA_BLOCK_LINK_VARIANT}
                        themeVariant={variant}
                        card
                    />
                </footer>
            )}
        </section>
    );
};

const ResourceCardsModule: React.FC<IResourceCardsModule & BaseModuleProps> = ({
    title,
    moduleDescription,
    resourceCards,
    variant,
    isContentFromContentful,
    useH1 = false,
}) => {
    if (isContentFromContentful) {
        variant = convertContentfulVariantToEnum(variant);
    }
    const baseOptions = { variant };
    const theme = useTheme();
    const [backgroundClass] = theme.generateStyles('background', baseOptions);
    const [textClass] = theme.generateStyles('text', baseOptions);
    const cardCount = resourceCards.length;
    const cardCountClass = `resource-card__count_${cardCount}`;
    const resourceCardsClasses = `resource-cards__grid ${cardCountClass}`;

    return (
        <section className={backgroundClass}>
            <div className="resource-cards-module module-landing-page__module-container-content">
                {title && (
                    <ResponsiveText
                        component={useH1 ? 'h1' : 'h2'}
                        base={{ fontSize: 52, lineHeight: 64 }}
                        small={{ fontSize: 32, lineHeight: 40 }}
                        className={`resource-cards-module__container__title ${textClass}`}
                    >
                        {title}
                    </ResponsiveText>
                )}

                {moduleDescription && (
                    <>
                        {isContentFromContentful ? (
                            <ResponsiveText
                                base={{ fontSize: 20, lineHeight: 28 }}
                                small={{ fontSize: 18, lineHeight: 24 }}
                                className={`resource-cards-module__container__description ${textClass}`}
                                applyCSSToChild={true}
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHTML(moduleDescription),
                                }}
                            ></ResponsiveText>
                        ) : (
                            <ResponsiveText
                                base={{ fontSize: 20, lineHeight: 28 }}
                                small={{ fontSize: 18, lineHeight: 24 }}
                                component="h3"
                                className={`resource-cards-module__container__description ${textClass}`}
                            >
                                {moduleDescription}
                            </ResponsiveText>
                        )}
                    </>
                )}
                <div className="resource-cards__container">
                    <div className={resourceCardsClasses}>
                        {resourceCards.map(
                            ({ headline, description, ctaBlock }) => (
                                <ResourceCardComponent
                                    key={uniqueId()}
                                    headline={headline}
                                    description={description}
                                    ctaBlock={ctaBlock}
                                    variant={variant}
                                />
                            ),
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ResourceCardsModule;
